<template>
  <div class="container">
    <div class="list-header">
      <van-icon @click="$router.push({path: '/'})" class="back-top-icon" name="arrow-left" />
      <div class="header-title">创建硬件用户</div>
      <div class="header-left">&nbsp;</div>
    </div>
    <div class="h85"></div>
    <div class="add-cell">
      <div class="add-cell-tip">孩子姓名</div>
      <van-field
        v-model.trim="form.name"
        label=""
        label-width="0"
        placeholder="请输入姓名"
        maxlength="10"
      />
    </div>
    <div class="add-cell">
      <div class="add-cell-tip">孩子年龄</div>
      <van-field
        v-model="form.age"
        label=""
        label-width="0"
        placeholder="请输入年龄"
        maxlength="3"
      />
    </div>
    <!-- 新增按钮 -->
    <div @click="submit" v-preventReClick class="add-btn">创建</div>
    <div class="h85"></div>
    <div class="h85"></div>    
  </div>
</template>

<script>
import { Icon, RadioGroup, Radio, Field, Popup, Picker } from 'vant';
import { range, forEach, isArray } from 'lodash';
import moment from 'moment';
import { getUserList, syncSubUser, creteUser, updateUser, delUser } from '@/api/user.js'
// console.log(tiZhongColumns, '----')

export default {
  components: {[Icon.name]: Icon, [RadioGroup.name]: RadioGroup, [Radio.name]: Radio, [Field.name]: Field, [Popup.name]: Popup, [Picker.name]: Picker},
  data() {
    return {
      form: {
        name: '',
        age: ''
      }
    }
  },
  created() {
    // 此页面 引导第一步
    this.$store.state.showStep = true
  },
  methods: {
    submit() {
      if(!this.form.name) {
        this.$toast('请输入姓名')
        return
      }
      const agePattern = /^([1-9]|[1-9]\d|1[0-1]\d|120)$/;
      if(!agePattern.test(this.form.age)) {
        this.$toast('请输入正确的年龄')
        return
      }
      creteUser({...this.form, id: undefined}).then(res => {
        this.$toast('新增成功')
        this.$store.state.showStep = true
        this.$router.push({path:'/'})
      }) 
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100vw;
  min-height: 100vh;
  background: #FFF;
  .add-btn {
    margin: 3rem auto;
    width: 4.17rem;
    height: .77rem;
    line-height: .77rem;
    background: #0074FE;
    border-radius: .38rem;
    color: #fff;
    text-align: center;
    font-weight: 500;
    font-size: .27rem;
  }
  .add-cell {
    padding: .8rem .6rem 0;
    .add-cell-tip {
      font-weight: 400;
      font-size: .27rem;
      color: #000000;
    }
    /deep/ .van-cell {
      border-bottom: .02rem solid #eee;      
      height: .62rem;
      line-height: .62rem;
      padding: 0;      
      font-weight: 400;
      font-size: .27rem;
      color: #999999;
    }
  }
  .h85 {
    height: .85rem;
  }
  .list-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    height: .85rem;
    padding: 0 .46rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFF;
    .back-top-icon {
      width: .3rem;
      font-size: .32rem;
      color: #000;
      font-weight: bolder;
    }
    .header-title {
      font-size: .31rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #000000;
    }
    .header-left {
      width: .3rem;
    }
  }
}
</style>